import { provideHttpClient, withInterceptorsFromDi, withJsonpSupport, withXsrfConfiguration } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// External modules
import { DialogService } from '@progress/kendo-angular-dialog';

import {
    BreadcrumbService,
    DialogActionService,
    NotificationService,
    PromptBeforeLeavingGuard,
    SimpleStorageService
} from '@fiba/utils';
import { AuditUtilsService } from '@fiba/utils/audit-utils.service';

import { DataCachedService } from '@fiba/data-services/utils/data-cached.service';
import { HttpService } from '@fiba/data-services/utils/http.service';
import { AgentTestDataService } from '@fiba/data-services/agent/agent-test-data.service';
import { AgentPreRegistrationDataService } from '@fiba/data-services/agent/agent-pre-registration-data.service';
import { AgentRegistrationDataService, CityDataService, DocumentDataService, TableOfficialEmailConfirmationDataService, PlayerReleaseConfirmationDataService, PlayerReleaseDocumentStatusDataService, GameOfficialConfirmationDataService } from '@fiba/data-services';
import { ItemDataService } from '@fiba/data-services/common/item-data.service';
import { CountryDataService } from '@fiba/data-services/common/country-data.service';
import { AgentRegistrationDocumentStatusDataService } from '@fiba/data-services/agent/agent-registration-document-status-data.service'
import { PaymentDataService } from '@fiba/data-services/payment/payment-data.service';

import { AppComponent } from './app.component';
import { BreadcrumbComponent } from './components/breadcrumb.component';
import { DialogActionComponent } from './components/dialog-action.component';
import { NotificationDisplayWrapperComponent } from './components/notification-display-wrapper.component';
import { NotificationDisplayComponent } from './components/notification-display.component';
import { RootComponent } from './components/root.component';
import { DummyRoutingComponent } from './components/dummy-routing.component';
import { SharedModule } from './shared.module';


// New architecture
import { FibaCommonModule } from '../@fiba/common';
import { FibaCommonSelectModule } from '../@fiba/common/fiba-common-select.module';
import { FibaFormsModule } from '../@fiba/forms';

import { TopMenuModule } from './components/topmenu/topmenu.module';
import { WindowRefService } from '@fiba/utils/utils.module';
import { TechnicalDelegateConfirmationDataService } from '@fiba/data-services/technical-delegate/technical-delegate-confirmation-data.service';
import { EquipmentApplicationDataService } from '../@fiba/data-services/equipment/equipment-application-data.service';

@NgModule({ bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        RootComponent,
        BreadcrumbComponent,
        NotificationDisplayComponent,
        NotificationDisplayWrapperComponent,
        DialogActionComponent,
        DummyRoutingComponent,
    ], imports: [BrowserModule,
        SharedModule.forRoot(),
        BrowserAnimationsModule,
        FibaCommonModule,
        FibaFormsModule,
        FibaCommonSelectModule,
        TopMenuModule], providers: [
        DialogActionService,
        NotificationService,
        DialogService,
        SimpleStorageService,
        BreadcrumbService,
        PromptBeforeLeavingGuard,
        AuditUtilsService,
        HttpService,
        DataCachedService,
        AgentTestDataService,
        AgentPreRegistrationDataService,
        AgentRegistrationDataService,
        ItemDataService,
        DocumentDataService,
        CityDataService,
        CountryDataService,
        AgentRegistrationDocumentStatusDataService,
        PaymentDataService,
        WindowRefService,
        TechnicalDelegateConfirmationDataService,
        TableOfficialEmailConfirmationDataService,
        PlayerReleaseConfirmationDataService,
        GameOfficialConfirmationDataService,
        PlayerReleaseDocumentStatusDataService,
        EquipmentApplicationDataService,
        provideHttpClient(withInterceptorsFromDi(), withJsonpSupport(), withXsrfConfiguration({
            cookieName: 'XSRF-TOKEN',
            headerName: 'X-XSRF-TOKEN',
        }))
    ] })
export class CoreModule {
}
